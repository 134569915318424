.StatItem {
  &__label {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    margin-bottom: 0.5rem;

    span {
      text-transform: uppercase;
      font-size: var(--font-size-secondary);
      line-height: 1.375rem;
      color: var(--pal-text-secondary);
      font-weight: var(--font-weight-medium);
    }
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--pal-text-primary);
    font-weight: var(--font-weight-medium);
  }

  &__note {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: var(--font-weight-medium);

    &--positive {
      color: var(--pal-success);
    }

    &--negative {
      color: var(--pal-error);
    }
  }
}
