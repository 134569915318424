// TODO: add to SDS
table.Table th:first-child:not(:last-child),
table.Table td:first-child:not(:last-child) {
  z-index: 1;
}

.TableContainer {
  position: relative;

  &--loading {
    opacity: var(--opacity-disabled-button);
    pointer-events: none;
    overflow-y: hidden;

    .Loader {
      --Loader-color: var(--pal-brand-primary);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.SortableTable {
  td:not(:first-child):not(:nth-child(2)) {
    text-align: right;
  }
  th {
    .sortable {
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding-right: 1.5rem;
      user-select: none;

      .SortableTable__sort {
        right: 0;
      }
    }

    &:not(:first-child):not(:nth-child(2)) {
      text-align: right;

      .sortable {
        padding-right: 0;
        padding-left: 1.5rem;

        .SortableTable__sort {
          left: 0;
        }
      }
    }
  }

  &__sort {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
      stroke: currentColor;
      width: 100%;
      height: 100%;
    }
  }

  &__clickableRow {
    cursor: pointer;
  }
}
