.FooterWithNote {
  margin-top: 2rem;

  &__note {
    font-size: 0.625rem;
    color: var(--pal-text-tertiary);
    line-height: 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__footer {
    margin-top: 4rem;
  }
}
