.PoolStats {
  &__items {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__token {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.75rem;

    .Avatar {
      --Avatar-size: 1.5rem;
      --Avatar-border-color: var(--pal-border-primary);
    }
  }
}
