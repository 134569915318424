#root {
  // TODO: add to SDS color palette (same for dark and light theme)
  --pal-background-tooltip: #191b25;

  // Making it default for now
  .Avatar {
    --Avatar-image-size: 100%;
  }
}

// TODO: update in SDS
// screen sizes below 360px are small % now (< 8%) https://worship.agency/mobile-screen-sizes-for-2021
body {
  --layout-window-width-min: 360px;
}

.Section {
  margin-top: 2rem;
}

.NotFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .Eyebrow {
    color: var(--pal-brand-primary);
  }
}

// Pool details
.PoolDetails {
  &__title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-weight: var(--font-weight-medium);
      margin-left: 0.5rem;
    }

    .Avatar {
      --Avatar-size: 2rem;
      --Avatar-image-size: 100%;
    }
  }
}
