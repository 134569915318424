.Breadcrumbs {
  display: flex;
  align-items: center;
  line-height: 1.5rem;

  &__link {
    a {
      font-size: var(--font-size);
      color: var(--pal-text-secondary);
      font-weight: var(--font-weight-medium);
      text-decoration: none;
      transition: color var(--anim-transition-default);

      &:hover {
        color: var(--pal-text-primary);
      }
    }

    &::after {
      content: ">";
      display: inline-block;
      margin: 0 0.75rem;
      font-size: var(--font-size-secondary);
      font-weight: var(--font-weight-medium);
    }

    &:last-child {
      a {
        color: var(--pal-text-primary);
        pointer-events: none;
      }

      &::after {
        display: none;
      }
    }
  }
}
