.NetworkIndicator {
  background-color: var(--pal-background-secondary);
  border: 1px solid var(--pal-border-primary);
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__indicator {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.25rem;
    margin-right: 0.25rem;

    &--testnet {
      background-color: var(--pal-brand-primary);
    }

    &--public,
    &--custom {
      background-color: var(--pal-success);
    }
  }

  &__label {
    text-transform: uppercase;
    font-size: var(--font-size-secondary);
    color: var(--pal-text-primary);
    font-weight: var(--font-weight-medium);
    line-height: 1.375rem;
  }
}
